import React from 'react';
import { useNavigate } from 'react-router-dom';
import elvenHunterThumbnail from '../assets/images/dolls/ElvenHunter/ElvenHunter-thumbnail.png';
import cyberHackerThumbnail from '../assets/images/dolls/CyberHacker/CyberHacker-thumbnail.png';
import darkMageThumbnail from '../assets/images/dolls/DarkMage/DarkMage-thumbnail.png';
import phoenixFighterThumbnail from '../assets/images/dolls/PhoenixFighter/PhoenixFighter-thumbnail.png';
import twilekScoundrelThumbnail from '../assets/images/dolls/TwilekScoundrel/TwilekScoundrel-thumbnail.png';
import demonSlayerRedThumbnail from '../assets/images/dolls/DemonSlayerRed/DemonSlayerRed-thumbnail.png';
import TwoBThumbnail from '../assets/images/dolls/2B/2B-thumbnail.png';
import SallyThumbnail from '../assets/images/dolls/Sally/Sally-thumbnail.png';
import watermark from '../assets/images/AI-Watermark.png';
import '../App.css';

const DollSelectionPage = () => {
  const navigate = useNavigate();

  // Navigation handlers for each doll
  const handleSelectDoll = (path) => {
    navigate(path);
  };

  // Navigate back to mode selection
  const handleReturnToModeSelect = () => {
    navigate('/mode-selection');
  };

  return (
    <div className="doll-selection-page">
      <h1>Select Your Doll</h1>

      <div className="doll-grid">
        <div className="doll-item" onClick={() => handleSelectDoll('/doll/Sally')}>
          <img src={SallyThumbnail} alt="Sally" className="thumbnail" />
          <p>Sally - The Nightmare Before Christmas</p>
        </div>

        <div className="doll-item" onClick={() => handleSelectDoll('/doll/2B')}>
          <img src={TwoBThumbnail} alt="2B" className="thumbnail" />
          <p>2B</p>
        </div>

        <div className="doll-item" onClick={() => handleSelectDoll('/doll/demonslayerred')}>
          <img src={demonSlayerRedThumbnail} alt="Demon Slayer Red" className="thumbnail" />
          <p>Demon Slayer Red</p>
        </div>

        <div className="doll-item" onClick={() => handleSelectDoll('/doll/twilekscoundrel')}>
          <img src={twilekScoundrelThumbnail} alt="Twilek Scoundrel" className="thumbnail" />
          <p>Twilek Scoundrel</p>
        </div>

        <div className="doll-item" onClick={() => handleSelectDoll('/doll/phoenixfighter')}>
          <img src={phoenixFighterThumbnail} alt="Phoenix Fighter" className="thumbnail" />
          <p>Phoenix Fighter</p>
        </div>

        <div className="doll-item" onClick={() => handleSelectDoll('/doll/darkmage')}>
          <img src={darkMageThumbnail} alt="Dark Mage" className="thumbnail" />
          <p>Dark Mage</p>
        </div>

        <div className="doll-item" onClick={() => handleSelectDoll('/doll/cyberhacker')}>
          <img src={cyberHackerThumbnail} alt="Cyber Hacker" className="thumbnail" />
          <p>Cyber Hacker</p>
        </div>

        <div className="doll-item" onClick={() => handleSelectDoll('/doll/elvenhunter')}>
          <img src={elvenHunterThumbnail} alt="Elven Hunter" className="thumbnail" />
          <p>Elven Hunter</p>
        </div>
      </div>

            {/* Return to Mode Select Button */}
            <button
        onClick={handleReturnToModeSelect}
        style={{
          backgroundColor: '#00bfff',
          color: '#fff',
          padding: '10px 20px',
          marginTop: '20px',
          border: 'none',
          cursor: 'pointer',
          fontSize: '16px'
        }}
      >
        Return to Mode Select
      </button>

      {/* Footer Section */}
      <footer className="footer">
        <div>
          <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
            <img src={watermark} alt="Support me on Patreon" className="select-watermark" />
          </a>
          <p>
            <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
              Support me on Patreon by clicking here
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default DollSelectionPage;
